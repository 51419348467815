@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }
}

.ck-content>blockquote,
.ck-content>dl,
.ck-content>dd,
.ck-content>h1,
.ck-content>h2,
.ck-content>h3,
.ck-content>h4,
.ck-content>h5,
.ck-content>h6,
.ck-content>hr,
.ck-content>figure,
.ck-content>p,
.ck-content>pre {
    margin: revert;
}

.ck-content>ol,
.ck-content>ul {
    list-style: revert;
    margin: revert;
    padding: revert;
}

.ck-content>table {
    border-collapse: revert;
}

.ck-content>h1,
.ck-content>h2,
.ck-content>h3,
.ck-content>h4,
.ck-content>h5,
.ck-content>h6 {
    font-size: revert;
    font-weight: revert;
}

.content * {
    all: revert;
}